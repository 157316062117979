import React, { Component, ReactNode } from 'react';
import Eyebrows from './complements/eyebrows';
import { BgImage } from 'gbimage-bridge';
import ReactHtmlParser from 'react-html-parser';
import { IGatsbyImageData } from 'gatsby-plugin-image';

interface Props {
  eyebrow1: string;
  eyebrow2: string;
  textColor: string;
  title?: string;
  titleClass?: string;
  backgroundImageMobile?: IGatsbyImageData;
  backgroundImage?: IGatsbyImageData;
}

class MasterEyebrows extends Component<Props> {
  render(): ReactNode {
    const { backgroundImage, backgroundImageMobile } = this.props;
    const sources: any = backgroundImageMobile
      ? [
          backgroundImageMobile,
          {
            ...backgroundImage,
            'media': '(min-width: 768px)',
          },
        ]
      : backgroundImage;
    const titleProp = this.props.title ? (
      <div className="mobile:pt-20 tablet:pt-24">
        <h1 className={this.props.titleClass ?? ''}>{ReactHtmlParser(this.props.title)}</h1>
      </div>
    ) : (
      <></>
    );

    const children = this.props.children ? this.props.children : <></>;

    return backgroundImage ? (
      <BgImage
        image={sources}
        bg-auto
        {...{ className: 'w-full h-80 tablet:h-84 laptop-standard:h-90' }}
      >
        <div className="container max-width padding-lateral pt-6 laptop-standard:pt-13">
          <Eyebrows
            eyebrow1={this.props.eyebrow1}
            eyebrow2={this.props.eyebrow2}
            textColor={this.props.textColor}
          />
        </div>
      </BgImage>
    ) : (
      <div className="bg-black h-80 tablet:h-84 laptop-standard:h-90">
        <div className="container max-width padding-lateral pt-6 laptop-standard:pt-13">
          <Eyebrows
            eyebrow1={this.props.eyebrow1}
            eyebrow2={this.props.eyebrow2}
            textColor={this.props.textColor}
          />
          {titleProp}
          {children}
        </div>
      </div>
    );
  }
}

export default MasterEyebrows;
